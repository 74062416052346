//#region @ Imports
import "./style.css";
import * as THREE from "three";
import { MapControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as dat from "dat.gui";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";
import Stats from "three/examples/jsm/libs/stats.module";
import { Interaction } from "three.interaction";
//#endregion

//#region @ Global Variables
let camera, scene, renderer, gltfLoader, listener, clock, controls;
let mousePosX, mousePosY;
let rodAnimations, rodThrown, isThrowingOrCatching;
let waterLabel = "Throw";
let stats; // debug

// Settings
const shadowsEnabled = false;
const fogEnabled = true;
const debugEnabled = false;
const underDevelopmentEnabled = false;
const particlesEnabled = true;
const isMobile = window.matchMedia(
  "only screen and (max-width: 760px)"
).matches;

let hoveredMesh = null;
let labelOffset = new THREE.Vector2();
let snapLabelToPointer = false;

const labelContainerElem = document.querySelector("#labels");
const label = createLabel();
const tempV = new THREE.Vector3();

const skyColor = 0x000000;
const lightColor = "#ffede5";
const mainStarColor = 0xffff00;
const secondaryStarColor = 0xffff90;

// Utilities
const degInRad = (deg) => (deg * Math.PI) / 180;
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// Projects
const projects = {
  anim8: {
    name: "Anim8",
    description:
      "Mobile App",
    url: "https://theodoratodorova.com/project-anim8.html",
    imageUrl: "models/anim8.png",
  },
  phdInSpace: {
    name: "PhD In Space",
    description:
      "PC & Console Game",
    url: "https://theodoratodorova.com/project-phd-in-space.html",
    imageUrl: "models/phd-in-space.png",
  },  
  staySafe: {
    name: "Stay Safe",
    description:
      "PC & Console Game",
    url: "https://theodoratodorova.com/project-stay-safe.html",
    imageUrl: "models/stay-safe.png",
  },
  minionsOfDoom: {
    name: "Minions Of Doom",
    description:
      "VR Game",
    url: "https://theodoratodorova.com/project-minions-of-doom.html",
    imageUrl: "models/minions-of-doom.png",
  },
  armyOfaHundred: {
    name: "Army Of A Hundred",
    description:
      "Mobile Game",
    url: "https://games.theodoratodorova.com/#portfolio/pages/army-of-a-hundred",
    imageUrl: "models/army-of-a-hundred.png",
  },
  // divineIntervention: {
  //   name: "Divine Intervention",
  //   description: "",
  //   position: new THREE.Vector3(2, 0, 0),
  //   url: "",
  //   imageUrl: "",
  //   modalFile: ".gltf",
  // },
};

const workExperience = {
  0: {
    name: "",
    title: "",
    description:
      "",
    location: "",
    startDate: "",
    endDate: "",
  },

  1: {
    name: "eXtended",
    title: "Intern Unreal Developer",
    description: "Implemented scene interactions for the BCU's GFA virtual showcase. Worked in an agile environment using Unreal Engine and Blueprints.",
    startDate: "Jun 2021",
    endDate: "Sep 2021",
  },
  2: {
    name: "Engine Creative",
    title: "Unity Developer",
    description: "Developed AR shopping applications, interactive showrooms and visually appealing virtual environments for brands to showcase their products.",
    startDate: "Sep 2021",
    endDate: "March 2022",
  },
  3: {
    name: "VU.City",
    title: "C# Software Developer",
    description: "Helped building a platform for visualizing and analyzing detailed 3D cities. Worked on developing features such as hiding terrain, slicing blocks of models, utilizing different weather presets using Enviro, and importing, converting, and exporting data.",
    startDate: "May 2022",
    endDate: "Aug 2023",
  },
  4: {
    name: "Mesmerise Group",
    title: "XR Developer",
    description: "Worked on VR interaction shared packages, and implemented immersive gameplay mechanics targeting cutting-edge devices such as Magic Leap 2, Hololens, Meta Quest 2, 3 & Pro.",
    startDate: "Sep 2023",
    endDate: "Apr 2024",
  },
};
// Excluded Objects
const excludedMeshes = ["IBilboard6", "IStar5", "IStar5b", "Plane"];

// Contact links
const contactLinks = {
  phone: {
    name: "Phone",
    objectName: "IPhone",
    url: "tel:+447737725944",
  },
  email: {
    name: "Email",
    objectName: "IEmail",
    url: "mailto:hello@theodoratodorova.com",
  },
  github: {
    name: "GitHub",
    objectName: "IGithub",
    url: "https://github.com/thea-t",
  },
  website: {
    name: "Website",
    objectName: "IWebsite",
    url: "https://theodoratodorova.com/",
  },
  linkedin: {
    name: "LinkedIn",
    objectName: "ILinkedin",
    url: "https://www.linkedin.com/in/thea-t/",
  },
  whatsapp: {
    name: "WhatsApp",
    objectName: "IWhatsapp",
    url: "https://api.whatsapp.com/send?phone=447737725944&text=Hi%20Thea%2C%20I%20got%20your%20WhatsApp%20information%20from%20your%20website.",
  },
};

const artistPageUrl = "https://www.linkedin.com/in/mystic-creations/";

const audios = [];
const animationMixers = [];
const starPositions = [];
const fishingRodAnimationNames = [
  "FishingRodIdle",
  "FishingRodThrow",
  "FishingRodCatch",
  "FishingRodDefault",
  "FishingRodFishBiteFake",
  "FishingRodFishBiteReal",
];
const fishingRodAnimationClips = [];

// Letters
const letterMeshes = [];
const letters = ["T", "H", "E", "O", "D", "R", "A"];
const letterSequence = [0, 1, 2, 3, 4, 3, 5, 6];
//#endregion

//#region @ HTML Elements
const canvas = document.querySelector("canvas.webgl");
const modal = document.querySelector(".modal");
const overlay = document.querySelector(".overlay");
const modalText = document.getElementById("modal-text");
const modalImage = document.getElementById("modal-image");
const btnCloseModal = document.querySelector(".modal-button-close");
const btnOpenProject = document.querySelector(".modal-button-visit");
const audioButton = document.querySelector(".mute-button");
const audioOnIcon = document.querySelector(".fa-volume-up");
const audioOffIcon = document.querySelector(".fa-volume-off");
const backButton = document.querySelector(".back-button");
const dragIconMouse = document.querySelector(".fa-mouse-pointer");
const dragIconCircle = document.querySelector(".fa-circle-o");
//#endregion

const loadManager = new THREE.LoadingManager();
init();
showProgressBar();

function init() {
  // Debug ///////////////////////////////////////////////////
  if (debugEnabled) {
    stats = Stats();
    document.body.appendChild(stats.dom);
  }

  // Loaders ////////////////////////////////////////////////
  gltfLoader = new GLTFLoader(loadManager);
  const textureLoader = new THREE.TextureLoader(loadManager);

  // Scene ////////////////////////////////////////////////
  scene = new THREE.Scene();

  // Clock ///////////////////////////////////////////////
  clock = new THREE.Clock();

  // Particles ///////////////////////////////////////////////
  if (particlesEnabled) {
    const particleTexture = textureLoader.load("textures/star_01.png");
    const particleMaterial = new THREE.PointsMaterial({
      size: 0.7,
      map: particleTexture,
      transparent: true,
      color: "#fffa86",
    });
    const particlesCount = 1000;
    const particleRadius = 40;
    const particlesGeometry = new THREE.BufferGeometry();
    // xyz, xyz, xyz, xyz...
    const posArray = new Float32Array(particlesCount * 3);
    for (let i = 0; i < particlesCount * 3; i++) {
      posArray[i] = (Math.random() - 0.5) * particleRadius;
    }

    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(posArray, 3)
    );

    const particlesMesh = new THREE.Points(particlesGeometry, particleMaterial);
    particlesMesh.position.set(2.5, 8, -47);

    scene.add(particlesMesh);
  }

  // Lights ////////////////////////////////////////////////
  const directionalLight = new THREE.DirectionalLight(lightColor, 1.5);
  directionalLight.position.x = 3;
  directionalLight.position.y = 15;
  directionalLight.position.z = 15;
  scene.add(directionalLight);

  // Fog //////////////////////////////////////////////////
  if (fogEnabled) scene.fog = new THREE.Fog(skyColor, 10, 35);

  // Sizes ////////////////////////////////////////////////
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  // Camera ///////////////////////////////////////////
  camera = new THREE.PerspectiveCamera(
    60,
    sizes.width / sizes.height,
    0.1,
    500
  );
  camera.position.y = 2;
  camera.position.z = 1.6;
  scene.add(camera);

  // Renderer ///////////////////////////////////////////////
  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setClearColor(skyColor);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.shadowMap.enabled = shadowsEnabled;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

  // Map Controls ////////////////////////////////////////
  controls = new MapControls(camera, renderer.domElement);
  controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
  controls.dampingFactor = 0.2;
  controls.screenSpacePanning = false;
  controls.minDistance = 1.2;
  controls.maxDistance = 20;
  controls.maxPolarAngle = Math.PI / 2;
  controls.enableRotate = debugEnabled;
  controls.enabled = false;
  // Interaction ////////////////////////////////////////////
  // new a interaction, then you can add interaction-event with your free style
  new Interaction(renderer, scene, camera);

  // Event Listeners ///////////////////////////////////////
  window.addEventListener("resize", onWindowResize);

  render();
  loadAllModels();
  debug();

  // Update ////////////////////////////////////////////////
  animate();
}
function animate() {
  if (debugEnabled) stats.update();

  TWEEN.update();
  // Call animate again on the next frame
  window.requestAnimationFrame(animate);

  // Update animation mixers
  for (let i = 0; i < animationMixers.length; i++) {
    animationMixers[i].update(clock.getDelta());
  }

  // Render
  render();

  if (controls.enabled) {
    // const minMax = 15;

    // controls.object.position.x = clamp(
    //   controls.object.position.x,
    //   -minMax,
    //   minMax
    // );
    // controls.object.position.z = clamp(
    //   controls.object.position.z,
    //   -minMax,
    //   minMax
    // );
    controls.update();
  }

  // Labels
  if (hoveredMesh) {
    hoveredMesh.updateWorldMatrix(true, false);
    hoveredMesh.getWorldPosition(tempV);
    // get the normalized screen coordinate of that position
    // x and y will be in the -1 to +1 range with x = -1 being
    // on the left and y = -1 being on the bottom
    tempV.project(camera);

    let x;
    let y;

    if (!snapLabelToPointer) {
      // convert the normalized position to CSS coordinates
      x = (tempV.x * 0.5 + 0.5) * canvas.clientWidth + labelOffset.x;
      y = (tempV.y * -0.5 + 0.5) * canvas.clientHeight + labelOffset.y;
    } else {
      x = mousePosX + labelOffset.x;
      y = mousePosY + labelOffset.y;
    }

    // move the elem to that position
    label.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
  }
}
function render() {
  renderer.render(scene, camera);
}
function debug() {
  if (underDevelopmentEnabled) {
    // Under development label
    const debugElem = createLabel();
    labelContainerElem.appendChild(debugElem);

    debugElem.innerHTML = "UNDER DEVELOPMENT";
    debugElem.style.visibility = "visible";
    debugElem.style.position = "fixed";
    debugElem.style.left = "43%";
    debugElem.style.top = "4%";
    debugElem.style.fontSize = "larger";
    debugElem.style.backgroundImage = "none";
  }

  if (debugEnabled) {
    // Direction Axis
    scene.add(new THREE.AxesHelper(5));

    // Debug
    const gui = new dat.GUI();

    //Camera
    const camPos = gui.addFolder("Camera Position");
    camPos.add(camera.position, "x").step(0.01);
    camPos.add(camera.position, "y").step(0.01);
    camPos.add(camera.position, "z").step(0.01);

    const camRot = gui.addFolder("Camera Rotation");
    camRot.add(camera.rotation, "x").step(0.01);
    camRot.add(camera.rotation, "y").step(0.01);
    camRot.add(camera.rotation, "z").step(0.01);
  }
}
function tweenLetters() {
  for (let i = 0; i < letterMeshes.length; i++) {
    setTimeout(() => {
      // position
      var targetPosition = new THREE.Vector3(0, 0.2, 0);
      new TWEEN.Tween(letterMeshes[i].position)
        .to(targetPosition, 500)
        .repeat(1)
        .yoyo(true)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onComplete(() => {
          letterMeshes[i].position.y = 0;
        })
        .start();

      // rotation
      var targetRotation = new THREE.Vector3(
        letterMeshes[i].rotation.x,
        letterMeshes[i].rotation.y,
        letterMeshes[i].rotation.z + Math.PI * 2
      );
      new TWEEN.Tween(letterMeshes[i].rotation)
        .to(targetRotation, 500)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onComplete(() => {
          letterMeshes[i].rotation.z = 0;
        })
        .start();
    }, 100 * i);
  }

  setTimeout(tweenLetters, 5000);
}
//#region @ EVENTS
function onMouseMove(e) {
  mousePosX = e.pageX;
  mousePosY = e.pageY;
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);

  render();
}

function addOnClickEventListener(mesh, onClick, onHoverEnter, onHoverExit) {
  let downEvent = isMobile ? "touchstart" : "mousedown";
  let upEvent = isMobile ? "touchend" : "mouseup";

  // add onClick event listener
  mesh.cursor = "pointer";
  let mousedown = false;
  mesh.on(downEvent, function (ev) {
    mousedown = true;
  });
  mesh.on(upEvent, function (ev) {
    if (mousedown) {
      onClick();
    }
    mousedown = false;
  });

  if (onHoverEnter) {
    mesh.on("mouseover", onHoverEnter);
  }

  if (onHoverExit) {
    mesh.on("mouseout", onHoverExit);
  }
}

//#endregion

//#region @ MODAL WINDOW
const defaultModalText = modalText.textContent;

overlay.addEventListener("click", hideModalWindow);
btnCloseModal.addEventListener("click", hideModalWindow);
document.addEventListener("keydown", function (e) {
  if (e.key === "Escape") {
    hideModalWindow();
  }
});

function showModalWindow(leavingText, sourceUrl) {
  modal.classList.add("fade-in");
  overlay.classList.add("fade-in");
  modal.classList.remove("fade-out", "hidden", "uninteractable");
  overlay.classList.remove("fade-out", "hidden", "uninteractable");
  modalText.textContent = defaultModalText + " " + leavingText + "?";
  modalImage.src = sourceUrl;
}
function hideModalWindow() {
  modal.classList.add("fade-out", "uninteractable");
  overlay.classList.add("fade-out", "uninteractable");
  modal.classList.remove("fade-in");
  overlay.classList.remove("fade-in");
}
//#endregion

//#region @ LABELS
function createLabel() {
  const elem = document.createElement("div");
  labelContainerElem.appendChild(elem);
  elem.style.verticalAlign = "bottom";
  return elem;
}
function showLabel(mesh, text, offset, snapToPointer) {
  if (snapToPointer) {
    document.addEventListener("mousemove", onMouseMove);
  }
  labelOffset = offset ? offset : new THREE.Vector2(0, 0);
  hoveredMesh = mesh;
  label.innerHTML = text;
  label.classList.remove("fade-out");
  label.classList.add("fade-in");
  label.style.visibility = "visible";
  snapLabelToPointer = snapToPointer;
}
function hideLabel() {
  document.removeEventListener("mousemove", onMouseMove);
  label.classList.remove("fade-in");
  label.classList.add("fade-out");
  //hoveredMesh = null;
}
//#endregion

//#region @ GLTF LOADERS
function loadGLTFModel(path, position, rotation, onLoad) {
  gltfLoader.load(
    path,
    // called when the resource is loaded
    function (gltf) {
      const meshes = [];
      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          const m = child;
          m.receiveShadow = shadowsEnabled;
          m.castShadow = shadowsEnabled;
          meshes.push(m);
        }
        if (child.isLight) {
          const l = child;
          l.castShadow = shadowsEnabled;
          l.shadow.bias = -0.003;
          l.shadow.mapSize.width = 2048;
          l.shadow.mapSize.height = 2048;
        }
      });

      if (position) {
        gltf.scene.position.x = position.x; //Position (x = right+ left-)
        gltf.scene.position.y = position.y; //Position (y = up+, down-)
        gltf.scene.position.z = position.z; //Position (z = front +, back-)
      }

      if (rotation) {
        gltf.scene.rotation.x += rotation.x;
        gltf.scene.rotation.y += rotation.y;
        gltf.scene.rotation.z += rotation.z;
      }

      scene.add(gltf.scene);
      onLoad(meshes);

      if (gltf.animations.length > 0) {
        rodAnimations = gltf.animations;
      }
    },
    // called while loading is progressing
    function (xhr) {
      if (debugEnabled) {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      }
    },
    // called when loading has errors
    function (error) {
      if (debugEnabled) {
        console.log("An error happened while loading object at " + path);
      }
    }
  );
}

function loadLetters() {
  const scale = 0.006;
  const spacing = 0.25;
  const xOffset = -4;
  const yOffset = 1.4;
  const zOffset = 1.7;
  const material = new THREE.MeshPhongMaterial({
    color: "#808080",
    flatShading: true,
  });

  // Letters
  for (let i = 0; i < letterSequence.length; i++) {
    loadGLTFModel(
      "models/letters/Letter_" + letters[letterSequence[i]] + ".gltf",
      new THREE.Vector3(spacing * (-2 + i) + xOffset, yOffset, zOffset),
      null,
      (m) => {
        m[0].scale.set(scale, scale, scale);
        letterMeshes[i] = m[0];
        m[0].material = material;
      }
    );
  }
}

function loadAllModels() {
  const mainStarMat = new THREE.MeshBasicMaterial({ color: mainStarColor });
  const secondaryStarMat = new THREE.MeshBasicMaterial({
    color: secondaryStarColor,
  });

  loadLetters();

  let airshipMeshes = [];
  const onAirshipLoaded = function () {
    const group = new THREE.Group();

    for (let i = 0; i < airshipMeshes.length; i++) {
      if (airshipMeshes[i].name == "AnAirshipFan") {
        var targetRotation = new THREE.Vector3(
          airshipMeshes[i].rotation.x,
          airshipMeshes[i].rotation.y,
          degInRad(360)
        );
        new TWEEN.Tween(airshipMeshes[i].rotation)
          .to(targetRotation, 3000)
          .easing(TWEEN.Easing.Linear.None)
          .repeat(Infinity)
          .start();
      }
      group.add(airshipMeshes[i]);
    }
    scene.add(group);

    var targetPos = new THREE.Vector3(
      group.position.x,
      group.position.y + 0.3,
      group.position.z
    );
    new TWEEN.Tween(group.position)
      .to(targetPos, 5000)
      .repeat(Infinity)
      .yoyo(true)
      .easing(TWEEN.Easing.Cubic.InOut)
      .start();
  };

  loadGLTFModel("models/FloatingIslandv2.gltf", null, null, (m) => {
    for (let i = 0; i < m.length; i++) {
      let excluded = false;
      for (let j = 0; j < excludedMeshes.length; j++) {
        if (excludedMeshes[j] === m[i].name) {
          excluded = true;
        }
      }
      if (excluded) {
        m[i].parent.remove(m[i]);
        m[i].geometry.dispose();
        m[i].material.dispose();
        if (debugEnabled) {
          console.log("Object IGNORED: " + m[i].name);
        }
        continue;
      }

      if (debugEnabled) {
        console.log("Object added: " + m[i].name);
      }

      if (m[i].name == "ILaptop") {
        m[i].add(skyrimSoundtrack);
        m[i].add(keyPressedSound);
        addOnClickEventListener(m[i], function () {
          keyPressedSound.play();
          if (skyrimSoundtrack.isPlaying) skyrimSoundtrack.pause();
          else skyrimSoundtrack.play();
        });
      } else if (m[i].name == "Icosphere1" || m[i].name == "Icosphere2") {
        // m[i].material = new THREE.MeshPhysicalMaterial({
        //   color: 0xb6b6b6,
        //   emissive: "#404040",
        // });
        var targetRot = new THREE.Vector3(
          m[i].rotation.x,
          m[i].rotation.y,
          m[i].rotation.z + degInRad(360)
        );
        new TWEEN.Tween(m[i].rotation)
          .to(targetRot, 2220)
          .repeat(Infinity)
          .easing(TWEEN.Easing.Linear.None)
          .start();
      } else if (
        m[i].name == "IAirship" ||
        m[i].name == "AirshipSign" ||
        m[i].name == "AnAirshipFan"
      ) {
        airshipMeshes.push(m[i]);

        if (airshipMeshes.length == 3) {
          onAirshipLoaded();
        }
      } else if (m[i].name == "ITelescope") {
        addOnClickEventListener(
          m[i],
          function () {
            telescopeView(m[i].position);
          },
          function () {
            showLabel(m[i], "<b>Work Experience");
          },
          function () {
            hideLabel();
          }
        );
      } else if (m[i].name == "SleepingCat") {
        m[i].add(catPurrSound);
      } else if (m[i].name == "ISwordForSignature") {
        addOnClickEventListener(
          m[i],
          function () {
            window.open(artistPageUrl, "_blank");
          },
          function () {
            showLabel(
              m[i],
              "Scene created by:<br><b>Mystic",
              new THREE.Vector3(0, 80, 0)
            );
          },
          function () {
            hideLabel();
          }
        );
      } else if (m[i].name == "FishingRod") {
        m[i].frustumCulled = false;

        // Create an AnimationMixer
        const mixer = new THREE.AnimationMixer(m[i].parent);
        animationMixers.push(mixer);
      } else if (m[i].name == "IWater") {
        let isHoveringOverWater = false;

        function updateLabel() {
          showLabel(
            m[i],
            "<b>" + waterLabel,
            new THREE.Vector3(0, 50, 0),
            true
          );
        }

        addOnClickEventListener(
          m[i],
          function () {
            if (isThrowingOrCatching) return;
            isThrowingOrCatching = true;
            if (!rodThrown) {
              rodThrown = true;
              hideLabel();

              stopAnimation(fishingRodAnimationClips[3], animationMixers[0]);
              //throw
              playAnimation(
                fishingRodAnimationClips[1],
                animationMixers[0],
                false,
                () => {
                  stopAnimation(
                    fishingRodAnimationClips[1],
                    animationMixers[0]
                  );
                  waterLabel = "Reel";
                  if (isHoveringOverWater) updateLabel();
                  isThrowingOrCatching = false;

                  // throw idle
                  playAnimation(
                    fishingRodAnimationClips[0],
                    animationMixers[0],
                    true
                  );
                }
              );
            } else {
              rodThrown = false;
              hideLabel();

              // throw idle
              stopAnimation(fishingRodAnimationClips[0], animationMixers[0]);
              // catch
              playAnimation(
                fishingRodAnimationClips[2],
                animationMixers[0],
                false,
                () => {
                  stopAnimation(
                    fishingRodAnimationClips[2],
                    animationMixers[0]
                  );
                  waterLabel = "Throw";
                  if (isHoveringOverWater) updateLabel();
                  isThrowingOrCatching = false;

                  playAnimation(
                    fishingRodAnimationClips[3],
                    animationMixers[0],
                    true
                  );
                }
              );
            }
          },
          function () {
            updateLabel();
            isHoveringOverWater = true;
          },
          function () {
            hideLabel();
            isHoveringOverWater = false;
          }
        );
      } else if (m[i].name == "LFire") {
        m[i].add(campfireSound);
        m[i].rotation.set(
          m[i].rotation.x + degInRad(15),
          m[i].rotation.y,
          m[i].rotation.z + degInRad(-10)
        );
        const targetScale = new THREE.Vector3(
          m[i].scale.x * 0.9,
          m[i].scale.y * 0.8,
          m[i].scale.z * 0.75
        );
        const targetRotation = new THREE.Vector3(
          m[i].rotation.x + degInRad(-10),
          m[i].rotation.y,
          m[i].rotation.z + degInRad(15)
        );
        new TWEEN.Tween(m[i].scale)
          .to(targetScale, 600)
          .repeat(Infinity)
          .yoyo(true)
          .easing(TWEEN.Easing.Sinusoidal.Out)
          .start();
        new TWEEN.Tween(m[i].rotation)
          .to(targetRotation, 1100)
          .repeat(Infinity)
          .yoyo(true)
          .easing(TWEEN.Easing.Linear.None)
          .start();
      } else if (m[i].name == contactLinks.email.objectName) {
        onContactLinkClicked(m[i], contactLinks.email);
      } else if (m[i].name == contactLinks.github.objectName) {
        onContactLinkClicked(m[i], contactLinks.github);
      } else if (m[i].name == contactLinks.whatsapp.objectName) {
        onContactLinkClicked(m[i], contactLinks.whatsapp);
      } else if (m[i].name == contactLinks.linkedin.objectName) {
        onContactLinkClicked(m[i], contactLinks.linkedin);
      } else if (m[i].name == contactLinks.phone.objectName) {
        onContactLinkClicked(m[i], contactLinks.phone);
      } else if (m[i].name == contactLinks.website.objectName) {
        onContactLinkClicked(m[i], contactLinks.website);
      } else if (m[i].name.includes("IStar")) {
        // last char in string
        let index = m[i].name.slice(-1);
        let customLabelOffset;
        let isBehind = false;
        m[i].lookAt(camera.position);

        if (index == "b") {
          // second last char in string
          index = m[i].name.slice(-2, -1);
          isBehind = true;
          m[i].position.z -= 0.1;
          m[i].material = secondaryStarMat;
          m[i].rotation.z += degInRad(45);
        } else {
          starPositions.push(m[i].position);
          m[i].material = mainStarMat;
        }

        if (index == 1) {
          m[i].position.x -= 5;
          customLabelOffset = new THREE.Vector2(-60, -230);
        } else if (index == 2) {
          m[i].position.x -= 1;
          m[i].position.y += 2;
          customLabelOffset = new THREE.Vector2(0, 300);
        } else if (index == 3) {
          m[i].position.x += 2;
          m[i].position.y -= 1;
          customLabelOffset = new THREE.Vector2(0, -250);
        } else if (index == 4) {
          m[i].position.x += 4;
          m[i].position.y += 2;
          customLabelOffset = new THREE.Vector2(180, 200);
        }

        let scale;
        let duration = Math.floor(Math.random() * 4000) + 2000;

        if (!isBehind) {
          addOnClickEventListener(
            m[i],
            function (ev) {},
            function (ev) {
              showLabel(
                m[i],
                "<b><font color='#ffeda5'>" +
                  workExperience[index].name +
                  "</b></font><br><font color='#ffedb5'>" +
                  workExperience[index].title +
                  "</font><br><i><font color='#ffedd5'>" +
                  workExperience[index].startDate +
                  " - " +
                  workExperience[index].endDate +
                  "</font></i><br><font color='#ffede5'>" +
                  workExperience[index].description,
                customLabelOffset
              );
            },
            function (ev) {
              hideLabel();
            }
          );
          scale = 1;
          m[i].scale.set(1.7, 1.7, 1.7);

          const targetScale = new THREE.Vector3(
            m[i].scale.x + scale,
            m[i].scale.y + scale,
            m[i].scale.z + scale
          );
          new TWEEN.Tween(m[i].scale)
            .to(targetScale, duration)
            .yoyo(true)
            .easing(TWEEN.Easing.Cubic.InOut)
            .repeat(Infinity)
            .start();
        } else {
          scale = 2.5;
          m[i].scale.set(0, 0, 0);
          duration /= 10;
          const delay = Math.floor(Math.random() * 9000) + 4000;

          const targetScale = new THREE.Vector3(
            m[i].scale.x + scale,
            m[i].scale.y + scale,
            m[i].scale.z
          );
          window.setInterval(function () {
            new TWEEN.Tween(m[i].scale)
              .to(targetScale, duration)
              .yoyo(true)
              .repeat(1)
              .onComplete(() => {
                m[i].scale.set(0, 0, 0);
              })
              .easing(TWEEN.Easing.Cubic.InOut)
              .start();
          }, delay);
        }
      } else if (m[i].name == "IBilboard2Anim8") {
        onBillboardClicked(m[i], projects.anim8);
      } else if (m[i].name == "IBilboard4PhdInSpace") {
        onBillboardClicked(m[i], projects.phdInSpace);
      } else if (m[i].name == "IBilboard1StaySafe") {
        onBillboardClicked(m[i], projects.staySafe);
      } else if (m[i].name == "IBilboard3MinionsOfDoom") {
        onBillboardClicked(m[i], projects.minionsOfDoom);
      } else if (m[i].name == "IBilboard5ArmyOfAHundred") {
        onBillboardClicked(m[i], projects.armyOfaHundred);
      }
      
    }
  });
}

function onContactLinkClicked(mesh, contactLink) {
  const gemTweenDuration = 800;
  const gemTweenYRotInDeg = degInRad(720);

  addOnClickEventListener(
    mesh,
    function () {
      window.open(contactLink.url, "_blank");
    },
    function () {
      showLabel(mesh, "<b>" + contactLink.name);

      let gemMesh;
      if (mesh.children.length == 0) {
        gemMesh = scene.getObjectByName(mesh.name + "Gem");
        mesh.add(gemMesh);
        gemMesh.scale.set(
          gemMesh.scale.x / mesh.scale.x,
          gemMesh.scale.y / mesh.scale.y,
          gemMesh.scale.z / mesh.scale.z
        );
        gemMesh.position.set(0, 0, 0);
        gemMesh.rotation.set(0, 0, 0);
      } else {
        gemMesh = mesh.children[0];
      }
      const targetPos = new THREE.Vector3(0, 2, 0);
      const targetRot = new THREE.Vector3(
        0,
        gemMesh.rotation.y + gemTweenYRotInDeg,
        0
      );

      new TWEEN.Tween(gemMesh.position)
        .to(targetPos, gemTweenDuration)
        .onComplete(() => {})
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
      new TWEEN.Tween(gemMesh.rotation)
        .to(targetRot, gemTweenDuration)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
    },
    function () {
      hideLabel();

      const gemMesh = mesh.children[0];
      const starPos = new THREE.Vector3(0, 0, 0);
      const startRot = new THREE.Vector3(
        0,
        gemMesh.rotation.y - gemTweenYRotInDeg,
        0
      );
      new TWEEN.Tween(gemMesh.position)
        .to(starPos, gemTweenDuration)
        .onComplete(() => {})
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
      new TWEEN.Tween(gemMesh.rotation)
        .to(startRot, gemTweenDuration)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
    }
  );
}

function onBillboardClicked(mesh, project) {
  addOnClickEventListener(
    mesh,
    function (ev) {
      showModalWindow(project.name, project.imageUrl);
      btnOpenProject.addEventListener("click", function () {
        window.open(project.url, "_blank");
      });
    },

    function () {
      showLabel(
        mesh,
        "<b>" +
          project.name +
          "</b><br><i><font color='#C0C0C0'>" +
          project.description +
          "</i>",
        new THREE.Vector2(0, 140)
      );
    },
    function () {
      hideLabel();
    }
  );
}
//#endregion

//#region @ AUDIO
// Audio Mute Button /////////////////////////////////
audioButton.addEventListener("click", muteAudio);

function muteAudio() {
  audioButton.removeEventListener("click", muteAudio);
  audioButton.addEventListener("click", unmuteAudio);
  audioOnIcon.classList.add("hidden");
  audioOffIcon.classList.remove("hidden");
  listener.setMasterVolume(0);
}
function unmuteAudio() {
  audioButton.removeEventListener("click", unmuteAudio);
  audioButton.addEventListener("click", muteAudio);
  audioOnIcon.classList.remove("hidden");
  audioOffIcon.classList.add("hidden");
  listener.setMasterVolume(1);
}

// Scene Audio ////////////////////////////////////
const audioLoader = new THREE.AudioLoader(loadManager);

// create an AudioListener and add it to the camera
listener = new THREE.AudioListener();
camera.add(listener);

// bird ambience
//createAudio(true, "audio/Birds Ambience.wav", 0.3, true);

const skyrimSoundtrack = createAudio(
  false,
  "audio/Laptop Soundtrack.aac",
  1,
  true,
  0.7,
  3
);
const campfireSound = createAudio(
  false,
  "audio/Campfire.aac",
  0.8,
  true,
  0.6,
  1
);

const keyPressedSound = createAudio(
  false,
  "audio/Key Press.mp3",
  1,
  false,
  0.5,
  0.5
);
const catPurrSound = createAudio(
  false,
  "audio/Cat Purr.aac",
  0.5,
  true,
  0.5,
  1
);

function createAudio(isGlobal, path, volume, loop, refDistance, maxDistance) {
  const audio = isGlobal
    ? new THREE.Audio(listener)
    : new THREE.PositionalAudio(listener);

  audioLoader.load(path, function (buffer) {
    audio.setBuffer(buffer);
    if (!isGlobal) {
      audio.setRefDistance(refDistance);
      audio.setMaxDistance(maxDistance);
    }
    audio.setVolume(volume);
    audio.loop = loop;
    if (loop) audios.push(audio);
  });
  return audio;
}

function playAllAudios() {
  setTimeout(function () {
    for (let i = 0; i < audios.length; i++) {
      audios[i].play();
    }
  }, 3000);
}

//#endregion

//#region @ CAMERA MOVEMENT

let isInTelescopeView = false;
let previousCameraPos = camera.position;
backButton.addEventListener("click", freeView);

function telescopeView(basePosition) {
  if (!isInTelescopeView) {
    isInTelescopeView = true;
    controls.saveState();
    controls.enabled = false;
    previousCameraPos = camera.position;
    var targetPosition = new THREE.Vector3(
      basePosition.x,
      basePosition.y + 1,
      basePosition.z + 0.8
    );
    new TWEEN.Tween(camera.position)
      .to(targetPosition, 1000)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onComplete(() => {
        var targetRotation = new THREE.Vector3(0.4, 0, 0);
        new TWEEN.Tween(camera.rotation)
          .to(targetRotation, 3000)
          .easing(TWEEN.Easing.Cubic.InOut)
          .start()
          .onComplete(() => {
            backButton.classList.remove("hidden");
            document.addEventListener("keydown", listenToEscapeKey);
          });
      })
      .start();
  }
}

function freeView() {
  if (isInTelescopeView) {
    backButton.classList.add("hidden");
    document.removeEventListener("keydown", listenToEscapeKey);
    var targetRotation = new THREE.Vector3(-1, 0, 0);
    new TWEEN.Tween(camera.rotation)
      .to(targetRotation, 3000)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onComplete(function () {
        var targetPosition = new THREE.Vector3(
          previousCameraPos.x,
          previousCameraPos.y + 1,
          previousCameraPos.z + 0.8
        );
        new TWEEN.Tween(camera.position)
          .to(targetPosition, 1000)
          .easing(TWEEN.Easing.Cubic.InOut)
          .onComplete(() => {
            controls.enabled = true;
            controls.reset();
            isInTelescopeView = false;
          })
          .start();
      })
      .start();
  }
}

function listenToEscapeKey(e) {
  if (e.key === "Escape") {
    freeView();
  }
}
//#endregion

//#region @ MOUSE DRAG ICON

function animateMousePointer() {
  dragIconMouse.classList.add("pointer-animate");
  dragIconCircle.classList.add("circle-animate");
}
function stopAnimateMousePointer() {
  dragIconMouse.classList.remove("pointer-animate");
  dragIconCircle.classList.remove("circle-animate");
  dragIconMouse.classList.add("hidden");
  dragIconCircle.classList.add("hidden");
  window.removeEventListener("click", stopAnimateMousePointer);
}
//#endregion

//#region @ LOADING SCREEN

const loadingScreen = document.getElementById("loading-screen");
const startButton = document.getElementById("buttonSliceContainer");
startButton.addEventListener("click", onStartButtonClicked);

function onStartButtonClicked() {
  loadingScreen.classList.add("fade-out", "uninteractable");
  controls.enabled = true;
  playAllAudios();
  setTimeout(() => {
    document.addEventListener("click", stopAnimateMousePointer);
  }, 100);
}

function onLoadingComplete() {
  animateMousePointer();
  startButton.classList.remove("hidden");
  drawLinesBetweenStars();
  tweenLetters();

  for (let i = 0; i < fishingRodAnimationNames.length; i++) {
    const clip = THREE.AnimationClip.findByName(
      rodAnimations,
      fishingRodAnimationNames[i]
    );
    fishingRodAnimationClips.push(clip);
  }

  // default idle
  playAnimation(fishingRodAnimationClips[3], animationMixers[0], true);

  console.log(scene);
}

function showProgressBar() {
  const fill = document.getElementById("progressFill");
  loadManager.onStart = function (url, itemsLoaded, itemsTotal) {
    if (debugEnabled) {
      console.log(
        "Started loading file: " +
          url +
          ".\nLoaded " +
          itemsLoaded +
          " of " +
          itemsTotal +
          " files."
      );
    }
  };

  loadManager.onLoad = function () {
    if (debugEnabled) {
      console.log("Loading complete!");
    }
    onLoadingComplete();
  };

  loadManager.onProgress = function (url, itemsLoaded, itemsTotal) {
    const width = (itemsLoaded / itemsTotal) * 100;
    fill.style.width = width + "%";
    if (debugEnabled) {
      console.log(
        "Loading file: " +
          url +
          ".\nLoaded " +
          itemsLoaded +
          " of " +
          itemsTotal +
          " files."
      );
    }
  };

  loadManager.onError = function (url) {
    if (debugEnabled) {
      console.log("There was an error loading " + url);
    }
  };
}

//#endregion

//#region @ OUTLINE EFFECT
const outlineMaterial = new THREE.MeshBasicMaterial({
  color: 0xff0000,
  side: THREE.BackSide,
});
let outlineMesh;

function showOutline(mesh) {
  // outlineMesh = new THREE.Mesh(mesh.geometry, outlineMaterial);
  // outlineMesh.scale.set(mesh.scale.x, mesh.scale.y, mesh.scale.z);
  // const meshPos = new THREE.Vector3();
  // const meshRotQuaternion = new THREE.Quaternion();
  // mesh.getWorldPosition(meshPos);
  // mesh.getWorldQuaternion(meshRotQuaternion);
  // const meshRotation = new THREE.Euler().setFromQuaternion(meshRotQuaternion);
  // outlineMesh.rotation.set(meshRotation.x, meshRotation.y, meshRotation.z);
  // outlineMesh.position.set(meshPos.x, meshPos.y, meshPos.z);
  // outlineMesh.scale.multiplyScalar(1.05);
  // scene.add(outlineMesh);
}

function hideOutline() {
  //scene.remove(outlineMesh);
}

//#endregion

function drawLinesBetweenStars() {
  const material = new THREE.LineBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.5,
  });
  const geometry = new THREE.BufferGeometry().setFromPoints(starPositions);
  const line = new THREE.Line(geometry, material);
  scene.add(line);
}

//#region @ ANIMATIONS

function playAnimation(clip, mixer, loop, onComplete) {
  // Play a specific animation
  const action = mixer.clipAction(clip);
  if (!loop) {
    action.setLoop(THREE.LoopOnce);
  }
  if (onComplete) {
    function _onComplete() {
      onComplete();
      mixer.removeEventListener("finished", _onComplete);
    }
    mixer.addEventListener("finished", _onComplete);
  }
  action.reset();
  action.play();
}
function stopAnimation(clip, mixer) {
  const action = mixer.clipAction(clip);
  action.stop();
}
//#endregion
